<template>
    <section  v-loading="loading"  element-loading-text="加載內容中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="column-left">
            <div class="title-lg">項目資料</div>
            <div class="photo-upload" v-if="!form.file">
                <div>
                    <custom-file-upload-single></custom-file-upload-single>
                </div>
            </div>
            <img v-else :src="form.file" style="width:100%;cursor:pointer;" @click="$refs.uploader.click()">
            <!-- 隱藏file上傳文件框 -->
            <input type="file" class="custom-file-upload-input" @change="file" ref="uploader"/>
            <custom-input
                v-model="form.project_video"
                placeholder="請輸入項目影片YouTube網址"
                @change="changeText('project_video')"
            ></custom-input>
            <div class="title-sm">目標金額</div>
            <div class="mix-radio-container">
 
                
                <span>HKD</span>
                <input type="number" v-model="form.target_amount"   min="0" class="custom-input input" @change="changeText('target_amount')">
                <span>/</span>
                <label class="custom-check-box-container">
                    <input
                        type="checkbox"
                        v-model="form.ta_constant_project"
                        class="custom-check-box-input"
                        @change="changeText('ta_constant_project')"
                    />
                    <div class="fake-custom-check-box"></div>
                    <slot></slot>
                </label>
                無上限
            </div>
<!--            <div class="title-sm">預計開始日期</div>-->
<!--            <v-date-picker v-model="form.estimated_start_time" >-->
<!--                <template v-slot="{ inputValue, inputEvents }">-->
<!--                    <input-->
<!--                    class="custom-input"-->
<!--                    :value="inputValue"-->
<!--                    v-on="inputEvents"-->
<!--                    />-->
<!--                </template>-->
<!--            </v-date-picker>-->
<!--            <div class="title-sm">項目時間</div>-->
<!--            <div class="mix-radio-container">-->
<!--                &lt;!&ndash; <custom-input-->
<!--                    class="input"-->
<!--                    v-model="form.project_time"-->
<!--                    placeholder="0-99"-->
<!--                    @change="changeText('project_time')"-->
<!--                ></custom-input> &ndash;&gt;-->
<!--                <input type="number" v-model="form.project_time"  placeholder="0-99"  min="0" max="99" oninput="value=value.replace(/[^\d]/g,'')" class="custom-input input" @change="changeText('project_time')">-->
<!--                <span>日</span>-->
<!--                <span>/</span>-->
<!--                <label class="custom-check-box-container">-->
<!--                    <input-->
<!--                        type="checkbox"-->
<!--                        v-model="form.constant_project"-->
<!--                        class="custom-check-box-input"-->
<!--                        @change="changeText('constant_project')"-->
<!--                    />-->
<!--                    <div class="fake-custom-check-box"></div>-->
<!--                    <slot></slot>-->
<!--                </label>-->
<!--                無上限-->
<!--            </div>-->
        </div>
        <div class="column-right">
            <div class="title-sm">籌款標題</div>
            <custom-input
                v-model="form.title"
                placeholder="請輸入籌款標題"
                @change="changeText('title')"
            ></custom-input>
            <div class="title-sm">籌款内文</div>
            <custom-textarea
                class="textarea"
                v-model="form.introduction"
                 @change="changeText('introduction')"
                placeholder="請輸入籌款内文"
            ></custom-textarea>
            <custom-check-box
                class="check-box"
                value="promisssion"
                v-model="form.agreementCheckList"
                 @change="changeText('agreementCheckList')"
            >
                我承諾所提交的文字與圖片資料完全真實，無任何虛構事實及隱瞞真相的情況，如有不實，我願意承擔所有法律責任。
            </custom-check-box>
            <custom-check-box
                class="check-box"
                value="agreement"
                v-model="form.agreementCheckList"
                @change="changeText('agreementCheckList')"

            >
                我已閱讀並同意《iMeddy籌款平台發佈條款》、《用戶協議》及《私隱協議》。
            </custom-check-box>
            <primary-button radius="none" class="next-button" @click="validate">
                提交
            </primary-button>
        </div>
        <div id="app">
			<div class="toast" v-show="toastShow">
				{{toastText}}
			</div>
		</div>
    </section>
</template>
<script>
import { apiFundraise } from '@/request/api';
import { slotFlagsText } from '@vue/shared';
export default {
    data() {
        return {
            loading:false,
            toastShow: false,
            toastText: '',
            form: {
                // 項目影片YouTube網址
                project_video: "",
                // 目标金额
                target_amount: 0,
                // 目标金额（是否是恆常項目）
                ta_constant_project: false,
                // 開始日期
                // estimated_start_time: new Date(),
                // 项目时间
                // project_time:0,
                // 籌款標題
                title: "",
                // 籌款内文
                introduction:"",
                // 项目时间（是否是恆常項目）
                // constant_project:false,
                // 条款
                agreementCheckList: [],
                // 圖片
                image: '',
                file:'',
            },
        };
    },
    watch: {
        // 'form.estimated_start_time': {
        //      handler: function() {
        //         this.$store.state.project.estimated_start_time = this.form.estimated_start_time;
        //      }
        // },
        files(val){
            this.form.file = val;
        },
        images(val){
            this.form.image = val;
        }
    },
    computed:{
        files(){
            return this.$store.state.file;
        },
        images(){
            return this.$store.state.project.image;
        }
    },
    created: function () {
            this.form.project_video = this.$store.state.project.project_video;
            this.form.target_amount = this.$store.state.project.target_amount;
            if(this.$store.state.project.ta_constant_project == 'Y'){
                this.form.ta_constant_project = true;
            }else{
                this.form.ta_constant_project = false;
            }

            // if(this.$store.state.project.constant_project == 'Y'){
            //     this.form.constant_project = true;
            // }else{
            //     this.form.constant_project = false;
            // }
            // this.form.estimated_start_time = this.$store.state.project.estimated_start_time;
            // this.form.project_time = this.$store.state.project.project_time;
            this.form.title = this.$store.state.project.title;
            this.form.introduction = this.$store.state.project.introduction;
            this.form.image = this.$store.state.project.image;
            this.form.agreementCheckList = this.$store.state.agreementCheckList;
    },
    methods: {
        projects_video(){
            let project_video = this.form.project_video;

            // var strRegex = "^((https|http|ftp|rtsp|mms)?://)"
            // + "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?" // ftp的user@
            // + "(([0-9]{1,3}\.){3}[0-9]{1,3}" // IP形式的URL- 199.194.52.184
            // + "|" // 允许IP和DOMAIN（域名）
            // + "([0-9a-z_!~*'()-]+\.)*" // 域名- www.
            // + "([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]\." // 二级域名
            // + "[a-z]{2,6})" // first level domain- .com or .museum
            // + "(:[0-9]{1,4})?" // 端口- :80
            // + "((/?)|" // a slash isn't required if there is no file name
            // + "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$";
            var strRegex = "(https?|ftp|file):\\/\\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]";
            var re = new RegExp(strRegex);

            if(project_video){
                if(!re.test(project_video)){
                    this.$message.error('請輸入完整項目影片url');
                    return 'error';
                }
                // let str = project_video.match(/http:\/\/.+/);
                // let strs = project_video.match(/https:\/\/.+/);
                // if(str == null && strs == null){
                //     this.$message.error('請輸入正確的網址');
                //     return 'error';
                // }
            }
        },
         file(e) {
            let file = e.target.files[0];
            let types = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
            let isUploadFile = types.includes(file['type']);
            
            let fileSizeFlag = file.size / 1024 / 1024  < 20 ? true : false;
            if (! isUploadFile) {
                this.$message.error('上傳文件只能是image、pdf格式')
                return false;
            }

            if(! fileSizeFlag) {
                this.$message.error('上傳文件大小不能超過20m');
                return false;
            }else{
                this.loading = true;
                let formData = new FormData();
                formData.append("file", file);
                formData.append("source", '籌款上傳');

                let that = this;
                that.$axios({
                    url:process.env.VUE_APP_API_SERVER+'/front/savefile/',
                    method: 'post',
                    data:formData
                }).then(function(res){
                    that.$store.state.project.image = res.data.data.file_url;
                    that.$store.state.file = res.data.data.file;
                    that.loading = false;
                }).catch(function(err){
                    that.loading = false;
                })
            }
        },
        introduction(){
            let introduction = this.form.introduction;
            if(introduction.length < 2){
                    this.$message.error('籌款内文不能少於 2 個字符');
                    return 'error';
            }
        },
        title(){
            let title = this.form.title;
            if(title.length < 2 || title.length > 50){
                    this.$message.error('籌款標題長度在 2 到 50 個字符');
                    return 'error';
            }
        },
        toast(str) {
            let v = this
            v.toastText = str
            v.toastShow = true
            setTimeout(function() {
                v.toastShow = false
            }, 1500)
        },
        changeText(val){
            if(val == 'project_video'){
                this.$store.state.project.project_video = this.form.project_video;
            }
            if(val == 'target_amount'){
                if(this.form.target_amount < 0){
                    this.form.target_amount = 0;
                }
                this.$store.state.project.target_amount = this.form.target_amount;
                this.form.ta_constant_project = false;
                this.$store.state.project.ta_constant_project = 'N';
            }
            if(val == 'ta_constant_project'){
                if(this.form.ta_constant_project === true){
                    this.$store.state.project.ta_constant_project = 'Y';
                    this.$store.state.project.target_amount = 0;
                    this.form.target_amount = 0;
                }else{
                     this.$store.state.project.ta_constant_project = 'N';
                }
            }
            // if(val == 'estimated_start_time'){
            //     this.$store.state.project.estimated_start_time = this.form.estimated_start_time;
            // }
            // if(val == 'project_time'){
            //     if(this.form.project_time > 99){
            //         this.form.project_time = 99;
            //     }
            //     this.$store.state.project.project_time = this.form.project_time;
            //     this.form.constant_project = false;
            //     this.$store.state.project.constant_project = 'N';
            // }
            // if(val == 'constant_project'){
            //     if(this.form.constant_project === true){
            //         this.$store.state.project.constant_project = 'Y';
            //         this.$store.state.project.project_time = 0;
            //         this.form.project_time = 0;
            //     }else{
            //          this.$store.state.project.constant_project = 'N';
            //     }
            // }

            if(val == 'title'){
                this.$store.state.project.title = this.form.title;
            }

            if(val == 'introduction'){
                this.$store.state.project.introduction = this.form.introduction;
            }

            if(val == 'agreementCheckList'){
                this.$store.state.agreementCheckList = this.form.agreementCheckList;
            }
        },
        validate() {
            let personals = this.$store.state.personal;
            // 儅用戶來到項目資料頁刷新界面時第一頁的内容會被清空
            if(!personals.age || !personals.email || !personals.explain_detail || !personals.other_files.length === 0 || !personals.related_medical_files.length === 0
            || !personals.gender || !personals.mobile_phone || !personals.name || !personals.patient_disease || !personals.patient_name || !personals.reason_application
            || !personals.to_gender || !personals.to_relation_type){
                this.$message.error('個人資料頁信息有誤,請檢查');
                return '';
            }
            if((this.projects_video() || this.introduction() || this.title() || this.projects_video()) == 'error'){
                return '';
            }
            //!this.form.project_video || !this.form.estimated_start_time ||
            if(!this.form.title || !this.form.introduction || !this.form.image){
                this.$message.error('請完善内容');
                return '';
            }
            if(this.projects_video() == 'error'){
                return '';
            }
            if(this.title() == 'error'){
                return '';
            }
            if(this.introduction() == 'error'){
                return '';
            }
            if(this.form.agreementCheckList.length < 2){
                this.$message.error('請勾選條款');
                return '';
            }
            if(!this.$store.state.project.introduction){
                if(this.form.ta_constant_project === true){
                    this.$store.state.project.ta_constant_project = 'Y';
                }else{
                   this.$store.state.project.ta_constant_project = 'N'; 
                }

                // if(this.form.constant_project === true){
                //     this.$store.state.project.constant_project = 'Y';
                // }else{
                //    this.$store.state.project.constant_project = 'N';
                // }
                this.$store.state.project.project_video = this.form.project_video;
                this.$store.state.project.target_amount = this.form.target_amount;
                // this.$store.state.project.estimated_start_time = this.form.estimated_start_time;
                // this.$store.state.project.project_time = this.form.project_time;
                this.$store.state.project.title = this.form.title;
                this.$store.state.project.introduction = this.form.introduction;
                this.$store.state.project.image = this.form.image;
                this.$store.state.agreementCheckList = this.form.agreementCheckList;
            }
            let project = this.$store.state.project;
            let personal = this.$store.state.personal;
            // let time = project.estimated_start_time;
            // let nian = time.getFullYear();
            // let yue = time.getMonth() + 1;
            // if(yue < 10){
            //     yue = '0'+yue;
            // }
            // let ri = time.getDate();
            // if(ri < 10){
            //     ri = '0'+ri;
            // }
            // let s = time.getHours();
            // let f = time.getMinutes();
            // let m = time.getSeconds();

            // project.estimated_start_time = nian+'-'+yue+'-'+ri;
            // +'T'+s+':'+f+':'+m
            this.loading = true;
            apiFundraise({project,personal}).then(res=>{
                if(res.code == "201"){
                    this.$message.error('數據提交失敗')
                    return false;
                }
                this.loading = false;
                this.$store.commit("newDonation/next");
                this.$router.push({ name: "newDonationStep3" });
            }).catch(function (error) { // 请求失败处理
                this.$message.error(error.data);
                return '';
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./newDonation.scss";
.photo-upload {
    background-color: $gray-100;
    border: 1px dashed $gray-900;
    text-align: center;
    line-height: 1.2;
    @include fr-c-c;
    padding: 20% 0;
}
.custom-file-upload-input {
        display: none;
    }
.mix-radio-container {
    @include fr-c-c;
    & > * {
        flex-basis: 10px;
        margin: 0 4px;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    .input {
        flex-grow: 1;
        margin: 8px 4px;
    }
    .radio {
        flex-basis: auto;
        margin: 0 4px;
    }
}
.column-right {
    @include fc-s-s;
    .textarea {
        flex-grow: 1;
    }
}
.check-box {
    line-height: 1.2;
    margin: 8px 0;
}
.toast {
			position: fixed;
			z-index: 2000;
			left: 50%;
			top: 45%;
			transition: all .5s;
			-webkit-transform: translateX(-50%) translateY(-50%);
			-moz-transform: translateX(-50%) translateY(-50%);
			-ms-transform: translateX(-50%) translateY(-50%);
			-o-transform: translateX(-50%) translateY(-50%);
			transform: translateX(-50%) translateY(-50%);
			text-align: center;
			border-radius: 5px;
			color: #FFF;
			background: rgba(17, 17, 17, 0.7);
			height: 45px;
			line-height: 45px;
			padding: 0 15px;
			max-width: 250px;
		}
</style>